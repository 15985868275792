import React, {Component} from 'react';
import {graphql, StaticQuery} from "gatsby";
import Img from "gatsby-image";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

class Contact extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			message: "I would like to discuss my website...",
			emailError: false,
			isSending: false,
			success: false,
			error: false,
			errorMsg: '',
			successMsg: '',
			token: '',
		}
	}

	handleInputChange = event => {
		event.preventDefault();
		const target = event.target;
		const fieldName = target.name;
		const value = target.value;

		console.log(fieldName)

		this.setState({
			[fieldName]: value
		});
	}

	isValid = () => {
		let formIsValid = true;
		const {email} = this.state
		if(email.trim().length <= 0){
			this.setState({emailError: true});
			formIsValid = false;
		}

		return formIsValid;
	}

	handleSubmit = event => {
		event.preventDefault();
		if(this.isValid()){
			this.setState({isSending: true});
			const form = event.target;
			let formData = new FormData(form);
			formData.append('g-recaptcha-response', this.state.token);
			//formData.append('email', this.state.email);
			//formData.append('message', this.state.message);
			fetch('https://getform.io/f/70aca77e-d7a0-41f0-8ac6-afabb7b7e7b9', {
				method: 'post',
				headers: {
					'Accept': 'application/json',
					//'Content-Type': 'application/x-www-form-urlencoded'
				},
				body: formData
			}).then(response => {
				return response.json();
			}).then(data => {
				if(data.success){
					this.setState({
						email: '',
						message: '',
						success: true,
						successMsg: 'Thank you!',
						error: false,
						isSending: false
					})
				}else{
					this.setState({
						success: false,
						errorMsg: 'Error in sending form',
						error: true,
						isSending: false
					})
				}
			}).catch(r => {
				this.setState({
					success: false,
					errorMsg: 'Error in sending form',
					error: true,
					isSending: false
				})
			})
		}
	}

	render() {
		return (
			<div className="bg-gray-light py-24 px-4 xl:px-0">
				<div className="container mx-auto">
					<div className="flex flex-wrap">
						<div className="w-full lg:w-1/2 lg:pr-10">
							<h1 className="text-center lg:text-left text-2xl md:text-4xl font-semibold">Have a question about your project?</h1>
							<p className="text-center lg:text-left text-lg mb-3">If you have any questions for us about your project, we would be happy to discuss them with you.</p>
							<StaticQuery
								query={graphql`
							        query HeadingQuery {
							          file(relativePath: { eq: "contactImg.jpg" }) {
									    ...fluidImage
								      }
							        }
							      `}
								render={data => (
									<Img fluid={data.file.childImageSharp.fluid}  alt="contact us image" className="hidden lg:block"/>
								)}
							/>
						</div>
						<div className="w-full lg:w-1/2 lg:pl-10">
							<GoogleReCaptchaProvider
								reCaptchaKey="6LcCl6gZAAAAALZ7eI09GfSdah7b2Ads1XZGIDK2"
								language="en"
							>
								<GoogleReCaptcha onVerify={token => this.setState({token})} />
								<form action="" onSubmit={this.handleSubmit}>
									<div className="bg-white rounded p-4 lg:p-10 flex flex-col shadow-lg">
											{this.state.success &&
												<div className="rounded relative text-white bg-green-600 border px-4 py-2 mb-8">{this.state.successMsg}</div>
											}
											{this.state.error &&
												<div className="rounded relative text-white bg-red-600 border px-4 py-2 mb-8">{this.state.errorMsg}</div>
											}
											<h5 className="font-light text-xl font-hero mb-3">Get a free consultation</h5>
											<input className="rounded border py-2 px-4 mb-5" type="email" placeholder="Your Email address" name="email"
											       onChange={this.handleInputChange} value={this.state.email} />
											<textarea className="rounded border  py-2 px-4 mb-5 "  rows="7" name="message" onChange={this.handleInputChange} value={this.state.message} />
											<button className="font-hero bg-blue-600 text-white p-2 rounded inline-block" disabled={this.state.isSending}>
												{this.state.isSending ? 'Sending...' : 'Send Message'}
											</button>
										</div>
								</form>
							</GoogleReCaptchaProvider>

						</div>
					</div>

				</div>
			</div>
		);
	}
}

export default Contact;
