import React from 'react';
import {StyledServiceItem} from "../Header/styles";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
const OurServices = () => {
	const  data  = useStaticQuery(
		graphql`
			query {
			    serviceIcon1: file(relativePath: { eq: "icons/services/service-icon-1.png" }) {
			      ...fixedImage
			    }
			    serviceIcon2: file(relativePath: { eq: "icons/services/service-icon-2.png" }) {
			      ...fixedImage
			    }
			    serviceIcon4: file(relativePath: { eq: "icons/services/service-icon-4.png" }) {
			      ...fixedImage
			    }
			    serviceIcon5: file(relativePath: { eq: "icons/services/service-icon-5.png" }) {
			      ...fixedImage
			    }
			    serviceIcon6: file(relativePath: { eq: "icons/services/service-icon-5.png" }) {
			      ...fixedImage
			    }
		    }
		    `
	)
	return (
		<div className="bg-gray-light py-24">
			<div className="container mx-auto">
				<div className="w-9/12 mx-auto text-center mb-12">
					<h2 className="text-3xl lg:text-4xl  leading-tight font-hero">What we can do for you</h2>
					<p className="lead">We help drive your business go faster</p>
				</div>

				<div className="flex flex-wrap">
					<StyledServiceItem className="w-full sm:w-1/2 lg:w-1/3 shadow z-10">
						<div className="h-full p-12 bg-white text-center ">
							<div>
								<Img fixed={data.serviceIcon1.childImageSharp.fixed} className="mx-auto mb-6" />
								<h5 className="mb-2 text-lg">Business Website</h5>
								<p>We develop sites with a focus on speed. It gives many benefits for your business!</p>
							</div>
						</div>
					</StyledServiceItem>
					<StyledServiceItem className="w-full sm:w-1/2 lg:w-1/3 shadow z-10">
						<div className="h-full p-12 bg-white text-center ">
							<div>
								<Img fixed={data.serviceIcon2.childImageSharp.fixed} className="mx-auto mb-6"/>
								<h5 className="mb-2 text-lg">Marketing Website</h5>
								<p>Static Sites fast and it gives higher conversion rates. Do not let your users wait for the site to load!</p>
							</div>
						</div>
					</StyledServiceItem>
					<StyledServiceItem className="w-full sm:w-1/2  lg:w-1/3 shadow z-10">
						<div className="h-full p-12 bg-white text-center ">
							<div>
								<Img fixed={data.serviceIcon4.childImageSharp.fixed} className="mx-auto mb-6"/>
								<h5 className="mb-2 text-lg">Media Website</h5>
								<p>Excellent content management system together with a static site will give huge benefits to your media site</p>
							</div>
						</div>
					</StyledServiceItem>
					<StyledServiceItem className="w-full sm:w-1/2  lg:w-1/3 shadow z-10">
						<div className="h-full p-12 bg-white text-center ">
							<div>
								<Img fixed={data.serviceIcon5.childImageSharp.fixed} className="mx-auto mb-6"/>
								<h5 className="mb-2 text-lg">E-commerce</h5>
								<p>Static sites are a great solution for E-Commerce. It is fast, convenient and SEO-friendly.</p>
							</div>
						</div>
					</StyledServiceItem>
					<StyledServiceItem className="lg:w-1/3 shadow z-10">
						<div className="h-full p-12 bg-white text-center ">
							<div>
								<Img fixed={data.serviceIcon6.childImageSharp.fixed} className="mx-auto mb-6"/>
								<h5 className="mb-2 text-lg">Creative Web design</h5>
								<p>Your website provides a place for interested users to learn more about business, your products and services.</p>
							</div>
						</div>
					</StyledServiceItem>
					<StyledServiceItem className="lg:w-1/3 shadow z-10">
						<div className="h-full p-12 bg-white text-center ">
							<div>
								<Img fixed={data.serviceIcon6.childImageSharp.fixed} className="mx-auto mb-6"/>
								<h5 className="mb-2 text-lg">Search engine optimization</h5>
								<p>We use search engines to market your product and they are love static fast websites!</p>
							</div>
						</div>
					</StyledServiceItem>
				</div>
			</div>
		</div>
	);
};

export default OurServices;
